.con {
  display: flex;
  padding-top: 3em;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}
.formContainer {
  margin-top: 2rem;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin-left: 3rem; */
  flex: 1;
}
.inputform {
  align-items: flex-end !important;
  justify-content: end !important;
}
.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.form div {
  margin-bottom: 1rem;
}
.label {
  font-size: 142%;
  text-align: center;
  /* margin-right: 2rem; */
}
.lastlabel {
  background-color: #ffca4b !important;
  color: #000 !important;
  padding: 1rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  cursor: pointer;
  min-width: 30%;
}
.lastlabel:hover {
  background-color: #c79d3d !important;
}
.right {
  flex: 1;
  width: 100%;
  /* border-left-style: dashed; */
  /* border-left-width: 2px; */
}
.rightContainer {
  /* padding-left: 2rem; */
  /* margin-top: 3rem; */
  width: 100%;
}
.rightHeading {
  font-size: 2rem;
  padding-bottom: 1em;
  text-align: center;
}

.checkContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 1rem;
  width: 100%;
}

.quote {
  /* margin-top: 1rem; */
  text-align: center;
  font-size: 2.5rem;
}
.yellowtext {
  color: #ffca4b;
}
.quoteText {
  /* margin-left: 3em; */
  text-align: center;
  font-size: 2rem;
  color: #ffca4b;
  margin-bottom: 2rem;
}

.formElementContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 50%;
}
@media screen and (max-width:1000px){
  .form{
    display: block;
  }
  
}
@media screen and (max-width:700px){
  .label{
    font-size: 1.2rem;
  }
  .rightHeading{
    font-size: 1.8rem;
  }

}
@media screen and (max-width:484px){
  .label{
    font-size: 0.9rem;
  }
  .rightHeading{
    font-size: 1.5rem;
  }
  
}