@import url("https://cdn.jsdelivr.net/npm/@xz/fonts@1/serve/cascadia-code.min.css");
@import url("https://cdn.jsdelivr.net/npm/@xz/fonts@1/serve/cascadia-code.min.css");
@import url("https://fonts.googleapis.com/css2?family=Balsamiq+Sans&family=Cairo:wght@300;400;700&display=swap");
/* ::-webkit-scrollbar {
  width: 5px;
}


::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}


::-webkit-scrollbar-thumb {
  background: rgb(37, 37, 37);
  border-radius: 10px;
} */
#root {
  padding-right: 1.5em;
  padding-left: 1.5em;
}

body {
  font-family: "Cascadia Code", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #18181b;
  overflow-x: hidden;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

a,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
div,
p,
button,
form,
input,
textarea,
select,
option,
table,
th,
td,
tbody,
tr,
thead,
footer,
header,
nav,
section,
article,
aside,
blockquote,
canvas,
dd,
dl,
dt,
fieldset,
figure,
figcaption,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  color: #fff;
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.yellow-color {
  color: #ffca4b;
}
.big {
  font-size: 2rem;
}

@media screen and (max-width: 576px) {
  body {
    width: 95vw;
  }
}
