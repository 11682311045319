.stats {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 2rem;
}

.statsTitle {
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 4rem;
}

.approached,
.done,
.responded {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.statNumber {
  color: #ffca4b;
  font-size: 2rem;
  padding-bottom: 1rem;
}

.companyTitles {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 5rem;
  font-size: 1.5rem;
}

.addButton {
  background-color: #ffca4b;
  padding: 0 0.4rem;
  border-radius: 15px;
  cursor: pointer;
}

.gotoButton {
  background-color: #ffca4b;
  padding: 0 0.4rem;
  border-radius: 15px;
  cursor: pointer;
  color: black;
}

.companyTable {
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 15px;
  border: 2px solid #ffca4b;
  margin-top: 2rem;
}

.headerRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.headerName {
  width: 60%;
  color: #ffca4b;
  border-bottom: 2px solid #ffca4b;
  padding-bottom: 0.75rem;
}

.headerStatus,
.headerContacted {
  width: 20%;
  color: #ffca4b;
  border-bottom: 2px solid #ffca4b;
  padding-bottom: 0.75rem;
}

.smol {
  font-size: 1.25rem;
}

.homeFooter {
  /* position: absolute; */
  box-sizing: border-box;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  text-align: center;
  width: 100%;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  font-size: 1.5em;
}

@media screen and (max-width: 910px) {
  .modal{
    width: 80%;
    overflow:scroll;
  }
}
@media screen and (max-width: 505px) {
  .companyTitles{
    margin-top:3rem;
    font-size: 1rem;
  }
  .smol{
    font-size: 1rem;
  }
  .statsTitle{
    margin-top: 3rem;
  }
  .stats{
    font-size: 70%;
  }
  .statsTitle{
    font-size: 1rem;
  }
}
@media screen and (max-width: 350px) {
  .companyTable {
    padding: 0.5rem;
    width: 100%;
  }
}
