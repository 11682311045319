.NavStack{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:space-between;
    padding: 1% 0;
}
.heading{
    font-size:300%;
}
.name{
    color:#FFCA4B;
}
.buttontop{
    padding :0.5rem 1rem 0.5rem 1rem !important;
    color:#FFCA4B !important;
    border-color: #FFCA4B !important;
    font-family: 'Balsamiq Sans', cursive !important ;
    font-size:100%  !important;
    border-radius: 18px !important;
}

.hub {
    display: block;
    font-family: sans-serif;
    font-weight: bold;
    font-size: 300%;
    /* position: absolute; */
    /* top: 50%; */
    /* left: 50%; */
    /* transform: translate(-50%, -50%); */
  }
  
  .hub span:nth-child(2) {
    background: #FF9900;
    color: #000000;
    border-radius: 1vw;
    padding: 0 1vw 0vw 1vw !important;
    display: inline-block;
  }

  @media screen and (max-width:640px) {
    .NavStack{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content:space-between;
        padding: 1% 0;
        font-size: 70%;
    }
  }


  @media screen and (max-width:505px) {
    .NavStack{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content:space-between;
        padding: 1% 0;
        font-size: 60%;
    }
  }
  @media screen and (max-width:460px) {
    .NavStack{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content:space-between;
        padding: 1% 0;
        font-size: 44%;
    }
  }
  @media screen and (max-width:380px) {
    .NavStack{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content:space-between;
        padding: 1% 0;
        font-size: 30%;
    }
  }