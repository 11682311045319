@import url("https://fonts.googleapis.com/css2?family=Kadwa:wght@400;700&display=swap");

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100vw;
  /* background-color: #262626; */
  /* background-color: #18181b; */

  font-family: "Kadwa", sans-serif;
}

.listbtn {
  text-decoration: none !important;
}

.mobileSheild {
  display: none;
}

.marketingPortal {
  display: none;
}

.left {
  width: 40%;
}
.leftContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 80vh;
  /* padding:1rem; */
  background-color: white;
  border-radius: 20px 0 0 20px;
}

.underline {
  border-bottom: 3px solid #0078d4;
}

.leftContainerHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  font-size: x-large;
  color: black;
  font-weight: bold;
}

.leftContainerHeader span {
  cursor: pointer;
}

/* .leftContainerHeader span:hover{
    border-bottom: 3px solid #0078D4;
} */

.loginFormContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;
}
.signupFormContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;
}

.formElement {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 1rem;
}

.formElement label {
  font-size: small;
  align-self: center;
  color: #00000099;
  padding-bottom: 0.2rem;
}
.formElement input {
  width: 60%;
  height: 2rem;
  border: none;
  background-color: #d9d9d93d;
  color: black;
  border-radius: 20px;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
}

.formElement input:focus {
  outline: none;
}

.formElement input[type="text"] {
  font-family: "Kadwa", serif;
}
.formElement input[type="email"] {
  font-family: "Kadwa", serif;
}

.formSubmit {
  background-color: #0078d4;
  width: 50%;
  text-align: center;
  border: none;
  border-radius: 40px;
  color: white;
  font-size: large;
  font-family: "Kadwa", sans-serif;
  cursor: pointer;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.right {
  width: 40%;
  height: 80vh;
  position: relative;
  text-align: center;
}

.rightImage {
  border-radius: 0 20px 20px 0;
  width: 100%;
  height: 100%;
}

.rightSheild {
  position: absolute;
  width: 30%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
@media screen and (max-width: 1040px) {
  .rightSheild {
    width: 50%;
  }
}

@media screen and (max-width: 1025px) {
  .right {
    display: none;
  }
  .left {
    width: 75%;
  }
  .leftContainer {
    height: fit-content;
    padding-top: 2rem;
    padding-bottom: 2rem;
    border-radius: 20px;
  }
  .leftContainerHeader {
    font-size: x-large;
  }

  .formElement label {
    font-size: large;
  }
  .formElement input {
    font-size: large;
    height: 4rem;
    width: 80%;
  }

  .container {
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 100vh;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .mobileSheild {
    display: block;
    width: 30%;
  }

  .marketingPortal {
    display: block;
    color: white;
    font-size: xx-large;
    font-weight: bolder;
  }

  .formSubmit {
    font-size: x-large;
    padding: 0.01rem;
  }
}

@media screen and (max-width: 640px) {
  .left {
    width: 80%;
  }
  .leftContainerHeader {
    font-size: large;
  }

  .formElement label {
    font-size: medium;
  }
  .formElement input {
    height: 2rem;
    width: 70%;
  }
  .formSubmit {
    font-size: medium;
  }
}

@media screen and (max-width: 480px) {
  .left {
    width: 90%;
  }
}

@media screen and (max-width: 300px) {
  .marketingPortal {
    font-size: x-large;
  }
}
